import { atom } from "recoil";

export const userState = atom({
  key: "user",
  default: {},
});

export const smsOptInShowingState = atom({
  key: "smsOptInShowing",
  default: false,
});
